<!--
 * @Author: Hou Jiechao
 * @Date: 2022-02-09 16:12:17
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-02-10 00:19:36
 * @FilePath: \工作\storehouse\src\components\particle.vue
-->
<template>
  <div id="container"></div>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { onMounted } from "vue";
export default {
  setup() {
    onMounted(() => {
      let container = document.getElementById("container");
      let WIDTH = window.innerWidth;
      let HEIGHT = window.innerHeight;
      let renderer,
        scene,
        camera,
        bloomPass,
        composer,
        stats,
        orbitcontrols;
      let mouseX = 0,
        mouseY = 0;
      let windowHalfX = window.innerWidth / 2;
      let windowHalfY = window.innerHeight / 2;
      window.addEventListener("resize", onWindowResize, false);
      const init = () => {
        initStats();
        initRenderer();
        initScene();
        initCamera();
        initControls();
        initRenderPass();
      };
      const initStats = () => {
        stats = new Stats();
        document.body.appendChild(stats.dom);
        stats.domElement.style.position = "absolute";
        stats.domElement.style.left = "0px";
        stats.domElement.style.top = "0px";
      };
      const initRenderer = () => {
        renderer = new THREE.WebGLRenderer({
          antialias: true,
          alpha: true,
          logarithmicDepthBuffer: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(WIDTH, HEIGHT);
        renderer.toneMapping = THREE.ReinhardToneMapping;
        container.appendChild(renderer.domElement);
        container.addEventListener("pointermove", onPointerMove);
      };
      const onPointerMove = (event) => {
        console.log(mouseX, mouseY);
        if (event.isPrimary === false) return;
        mouseX = event.clientX - windowHalfX;
        mouseY = event.clientY - windowHalfY;
      };
      const initScene = () => {
        scene = new THREE.Scene();
        var starsGeometry = new THREE.BufferGeometry();

        var positions = [];
        var sizes = [];
        for (var i = 0; i < 500; i++) {
          positions.push((Math.random() * 2 - 1) * 50);
          positions.push((Math.random() * 2 - 1) * 50);
          positions.push((Math.random() * 2 - 1) * 50);
          sizes.push(20);
        }
        starsGeometry.setAttribute(
          "position",
          new THREE.Float32BufferAttribute(positions, 3)
        );
        var starsMaterial = new THREE.PointsMaterial({ color: 0x949494 });
        var starField = new THREE.Points(starsGeometry, starsMaterial);
        console.log(starField);
        scene.add(starField);
      };
      const printPosition = () => {
        // console.info("X=" + camera.position.x);
        // console.info("Y=" + camera.position.y);
        // console.info("Z=" + camera.position.z);
      };
      const initControls = () => {
        orbitcontrols = new OrbitControls(camera, renderer.domElement);
        orbitcontrols.enableDamping = true; //惯性
        orbitcontrols.dampingFactor = 0.1; //惯性系数
        orbitcontrols.enablePan = false; // 禁止左右平移
        orbitcontrols.enableZoom = false; // 禁止缩放
        // orbitcontrols.autoRotate = true; // 自动旋转
        orbitcontrols.maxPolarAngle = Math.PI / 2; // 上下旋转的最小角度控制
        orbitcontrols.minPolarAngle = Math.PI / 2; // 上下旋转的最大角度控制
        orbitcontrols.addEventListener("change", printPosition);
      };
      const initCamera = () => {
        camera = new THREE.PerspectiveCamera(45, WIDTH / HEIGHT, 1, 10000);
        camera.position.set(3.31, 1.65, -270); // 设置相机位置(x, y z) y控制高度,z控制当前的距离
        camera.lookAt(scene.position); // 设置相机方向(指向的场景对象)
      };

      const initRenderPass = () => {
        // 加载后期发光效果
        var renderScene = new RenderPass(scene, camera);
        //辉光
        bloomPass = new UnrealBloomPass(
          new THREE.Vector2(window.innerWidth, window.innerHeight),
          1.6,
          0.1,
          0.1
        );
        bloomPass.strength = 2.6; // 发光强度
        bloomPass.radius = 0; // 发光半径
        bloomPass.threshold = 0.08; // 发光阈值
        renderer.toneMappingExposure = 1.2; //设置场景曝光度
        composer = new EffectComposer(renderer);
        composer.addPass(renderScene);
        composer.addPass(bloomPass);
      };
      const onWindowResize = () => {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };
      const updateParticles = () => {
        // console.log(particles.length);
        // for (var i = 0; i < particles.length; i++) {
        //   let particle = particles[i];
        //   // console.log(particle);
        //   //设置粒子向前移动的速度依赖于鼠标在平面Y轴上的距离
        //   // particle.position.z += mouseY * 0.1;
        //   // //如果粒子Z轴位置到1000,将z轴位置设置到-1000,即移动到原点,这样就会出现无穷尽的星域效果.
        //   // if (particle.position.z > 1000) {
        //   //   particle.position.z -= 2000;
        //   // }
        // }
      };
      const animate = () => {
        stats.update();
        if (orbitcontrols) orbitcontrols.update();
        requestAnimationFrame(animate);
        updateParticles();
        renderer.clear();
        renderer.render(scene, camera);
        // scene.rotation.y += 0.001;
        // scene.rotation.x += 0.001;
        composer.render();
      };
      init();
      animate();
    });
  },
};
</script>
<style scoped>
</style>