<!--
 * @Author: Hou Jiechao
 * @Date: 2022-02-09 16:12:17
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-02-09 16:36:15
 * @FilePath: \工作\storehouse\src\pages\3d\particle\index.vue
-->
<template>
  <Particle />
</template>

<script>
import Particle from "../../../components/particle.vue";

export default {
  name: "hotel_hfrd",
  components: {
    Particle,
  },
};
</script>